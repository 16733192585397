import { useLoaderData } from '@remix-run/react';
import { useEffect, useRef } from 'react';

export function capitalize(s: string, locale: string) {
  return s.charAt(0).toLocaleUpperCase(locale) + s.slice(1);
}

export function getLocale(request: Request) {
  const queryLang = new URL(request.url).searchParams.get('lng');
  return queryLang ?? 'en';
}

// framer motionを使ってroute transitionを実装する際、
// 画面遷移時に前のloader dataが消えてしまうので、キャッシュしておくためのhook
// https://github.com/remix-run/react-router/issues/8936
// https://github.com/remix-run/react-router/discussions/8008
export function useLoaderDataWithCache<T>(): ReturnType<
  typeof useLoaderData<T>
> {
  const prev = useRef<ReturnType<typeof useLoaderData<T>>>();
  const data = useLoaderData<T>();

  useEffect(() => {
    prev.current = data;
  }, [data]);

  const r = data ?? prev.current ?? null;
  if (r === null) {
    throw new Error('unexpected undefined loader data');
  }
  return r;
}
